@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700;900&display=swap');

@font-face {
  font-family: 'Makit';
  src: url('../fonts/static/Syne-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MakitBold';
  src: url('../fonts/static/Syne-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Makit', sans-serif;
  background-color: #010312;
  @apply text-[#2B383B];
}

.font-makitbold {
  font-family: 'MakitBold', sans-serif;
}

.text-black {
  color: #010312!important;
}

.hamburger-react {
  @apply float-right;
}

.bg-black {
  background-color: #010312!important;
}

.bg-white {
  background-color: #f8f8f8!important;
}

.navigation {
  background-color: rgba(1, 3, 18, .6);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
}

.text-poppins {
  font-family: 'Poppins', sans-serif;
}

.border-makit {
  border: 1px solid hsla(0,0%,100%,.05);
}

.border-bot-makit {
  border-bottom: 1px solid hsla(0,0%,100%,.1);
}

.border-t-makit {
  border-top: 1px solid hsl(0deg 0% 23.7% / 50%);
}

.border-b-makit {
  border: 1px solid hsl(0deg 0% 23.7% / 50%);
}

.bg-makit {
  background-color: rgb(11 0 19 / 50%);
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.subtitle {
  color: rgb(156 163 175);
}

video {
  border-radius: 50px!important;
  border: 1px solid hsl(0deg 0% 23.7% / 50%)!important;
  overflow: hidden!important;
}

.bg-pattern {
  animation: mover 20s linear infinite;
  background-image: url('../images/grid.svg');
  background-repeat: repeat;
  background-size: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hand-mover {
  display: inline-block;
  animation: hand-mover 2s infinite;
  @apply pr-1;
}

.fa-hourglass-half {
  animation: fullyRotated 4s infinite;
  @apply pr-1;
}

.menuOverflow {
  z-index: 45;
}

@keyframes mover {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes hand-mover {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes fullyRotated {
  0% {
    transform: rotateY(0deg);
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateY(360deg);
    transform: rotateX(360deg);
  }
}